import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import useAuth from "../hooks/useAuth";

import SEO from "../components/seo";
import Login from "../components/register-form/login";
import Signup from "../components/register-form/signup";
import Paws from "../components/paws";
import Logo from "../../static/assets/img/logo.svg";
import { isBrowser } from "../utils/isBrowser";
import { GetAuthContextFromLS } from "../utils/secure-local-storage";

import { MonitoringIdentityAuthServiceAsync } from "../services/monitoring";

export default function Home() {
  // this is a fire and forget call just for wake up the web api on heroku
  if (isBrowser) MonitoringIdentityAuthServiceAsync();

  const [page, setPage] = useState(true);
  const { state, login, signup, validateSession } = useAuth();

  if (
    state !== undefined &&
    state.isLoggedIn &&
    state.isActive &&
    !state.isExpired
  ) {
    navigate("/platform");
  }

  useEffect(() => {
    let data = GetAuthContextFromLS();

    validateSession(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleButton = () => {
    setPage((prevState) => {
      return !prevState;
    });
  };

  let imgClassName = page ? "mt-10" : "mt-56";
  let containerClassName = page ? "py-20 md:py-36" : "py-14";

  return (
    <div className="w-full wrapper">
      <SEO title="Meia Pata B2B Platform" />
      <div className="login-container-b">
        <div className="absolute top-0 bottom-0 right-0 left-0 overflow-hidden z-0">
          <Paws
            className="fill-current text-yellow-300 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "19deg",
              right: "10%",
              top: 10,
            }}
          />
          <Paws
            className="fill-current text-pink-500 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "10deg",
              right: "40%",
              top: 115,
            }}
          />
          <Paws
            className="fill-current text-blue-300 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "-40deg",
              left: "1%",
              top: 200,
            }}
          />
          <Paws
            className="fill-current text-blue-100 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "-40deg",
              left: "44%",
              top: 700,
            }}
          />
          <Paws
            className="fill-current text-pink-300 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "27deg",
              right: "1%",
              top: 800,
            }}
          />
          <Paws
            className="fill-current text-yellow-500 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "27deg",
              left: "30%",
              top: 1350,
            }}
          />
        </div>
        <div
          className={`container ${containerClassName} md:max-w-screen-lg bg-white flex justify-around rounded shadow-4xl z-10`}
        >
          <div className="hidden md:block ml-10 text-center">
            <img src={Logo} alt="Meia Pata Logo" className={imgClassName} />
            {page ? (
              <>
                <p className="hidden md:block text-base font-display mt-5 text-gray-500 -ml-3.5">
                  Not a member?{" "}
                  <button
                    className="text-blue-700 cursor-pointer underline"
                    onClick={handleButton}
                    disabled={state !== undefined && state.isLoading}
                  >
                    Signup here
                  </button>
                </p>
              </>
            ) : (
              <p className="hidden md:block text-base font-display mt-5 text-gray-500 -ml-3.5">
                Already a member?{" "}
                <button
                  className="text-blue-700 cursor-pointer underline"
                  onClick={handleButton}
                  disabled={state !== undefined && state.isLoading}
                >
                  Login here
                </button>
              </p>
            )}
          </div>
          {page ? (
            <Login
              submitHandler={login}
              isLoading={state !== undefined && state.isLoading}
            >
              <p className="md:hidden text-base font-display mt-5 text-gray-500 text-center">
                Not a member?{" "}
                <button
                  className="text-blue-700 cursor-pointer underline"
                  onClick={handleButton}
                  disabled={state !== undefined && state.isLoading}
                >
                  Signup here
                </button>
              </p>
            </Login>
          ) : (
            <Signup
              submitHandler={signup}
              isLoading={state !== undefined && state.isLoading}
            >
              <p className="md:hidden text-base font-display mt-5 text-gray-500 text-center">
                Already a member?{" "}
                <button
                  className="text-blue-700 cursor-pointer underline"
                  onClick={handleButton}
                  disabled={state !== undefined && state.isLoading}
                >
                  Login here
                </button>
              </p>
            </Signup>
          )}
        </div>
      </div>
    </div>
  );
}
