import React from "react";

const Spinner = () => (
  <>
    Loading{" "}
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </>
);

export default Spinner;
