import axios from "axios";
import {
  identityMonitoringUri,
  productMonitoringUri,
  checkoutMonitoringUri,
} from "../utils/settings";

export const MonitoringIdentityAuthServiceAsync = async () => {
  try {
    axios.get(identityMonitoringUri).catch(() => {
      return;
    });
    axios.get(productMonitoringUri).catch(() => {
      return;
    });
    axios.get(checkoutMonitoringUri).catch(() => {
      return;
    });
  } catch {
    return;
  }
};
