import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import Spinner from "../../spinner";
import {
  Locker,
  Email,
  User,
  FingerPrint,
  Country,
  Address,
  BriefCase,
  WebSite,
} from "../../shop-icons";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

const signupForm = ({ submitHandler, isLoading, children }) => {
  const schema = yup.object({
    fullName: yup.string().required("Full Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    taxIdentifierNumber: yup
      .number()
      .required("Tax identifier number is required"),
    password: yup
      .string()
      .matches(
        /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{6,30})/,
        `The Password should contain at least 6 chars: 
        one uppercase letter, number and a special character (@#$%!...)`
      )
      .required("Password is Required"),
    passwordConfirm: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords don't match")
      .required("Confirm Password is required"),
    country: yup.string().required("Country is required"),
    address: yup.string().required("Address is required."),
    companyName: yup.string().required("Company name is required."),
    companyWebsite: yup.string().required("Company website is required."),
    region: yup.string().required("Region is required"),
  });

  return (
    <React.Fragment>
      <Formik
        validationSchema={schema}
        onSubmit={submitHandler}
        initialValues={{
          fullName: "",
          taxIdentifierNumber: "",
          email: "",
          password: "",
          passwordConfirm: "",
          country: "",
          address: "",
          region: "",
          companyName: "",
          companyWebsite: "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <form
            className="login100-form max-w-xs"
            noValidate
            onSubmit={handleSubmit}
          >
            <span className="font-display text-blue-900 text-3xl lg:text-4xl text-center block -mt-2 mb-2">
              Signup
            </span>
            <div className="w-full relative z-10 mb-1">
              <input
                className="input100"
                name="fullName"
                placeholder="Full Name"
                value={values.fullName}
                onChange={handleChange}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <User className="w-5" />
              </span>
              {errors.fullName && touched.fullName && (
                <p className="ml-5 mb-2 text-sm font-thin">{errors.fullName}</p>
              )}
            </div>
            <div className="w-full relative z-10 mb-1">
              <input
                className="input100"
                type="email"
                name="email"
                placeholder="Email"
                value={values.email}
                onChange={handleChange}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <Email className="w-5" />
              </span>
            </div>
            {errors.email && touched.email && (
              <p className="ml-5 mb-2 text-sm font-thin">{errors.email}</p>
            )}
            <div className="w-full relative z-10 mb-1">
              <input
                className="input100"
                type="number"
                name="taxIdentifierNumber"
                placeholder="Tax Identifier Number"
                value={values.taxIdentifierNumber}
                onChange={handleChange}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <FingerPrint className="w-5" />
              </span>
            </div>
            {errors.taxIdentifierNumber && touched.taxIdentifierNumber && (
              <p className="ml-5 mb-2 text-sm font-thin">
                {errors.taxIdentifierNumber}
              </p>
            )}
            <div className="w-full relative z-10 mb-1">
              <input
                className="input100"
                type="text"
                name="companyName"
                placeholder="Company name"
                value={values.companyName}
                onChange={handleChange}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <BriefCase className="w-5" />
              </span>
            </div>
            {errors.companyName && touched.companyName && (
              <p className="ml-5 mb-2 text-sm font-thin">
                {errors.companyName}
              </p>
            )}
            <div className="w-full relative z-10 mb-1">
              <input
                className="input100"
                type="text"
                name="companyWebsite"
                placeholder="Company website"
                value={values.companyWebsite}
                onChange={handleChange}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <WebSite className="w-5" />
              </span>
            </div>
            {errors.companyWebsite && touched.companyWebsite && (
              <p className="ml-5 mb-2 text-sm font-thin">
                {errors.companyWebsite}
              </p>
            )}
            <div className="w-full relative z-10 mb-1">
              <input
                className="input100"
                type="text"
                name="address"
                placeholder="Complete address"
                value={values.address}
                onChange={handleChange}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <Address className="w-5" />
              </span>
            </div>
            {errors.address && touched.address && (
              <p className="ml-5 mb-2 text-sm font-thin">{errors.address}</p>
            )}
            <div className="w-full relative z-10 mb-1">
              <CountryDropdown
                className="input100"
                name="country"
                value={values.country}
                onChange={(_, e) => {
                  handleChange(e);
                }}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <Country className="w-5" />
              </span>
            </div>
            {errors.country && touched.country && (
              <p className="ml-5 mb-2 text-sm font-thin">{errors.country}</p>
            )}
            <div className="w-full relative z-10 mb-1">
              <RegionDropdown
                className="input100"
                blankOptionLabel="Region"
                disableWhenEmpty={true}
                name="region"
                country={values.country}
                value={values.region}
                onChange={(_, e) => {
                  handleChange(e);
                }}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <Country className="w-5" />
              </span>
            </div>
            {errors.country && touched.country && (
              <p className="ml-5 mb-2 text-sm font-thin">{errors.country}</p>
            )}
            <div className="w-full relative z-10 mb-1">
              <input
                className="input100"
                type="password"
                name="password"
                placeholder="Password"
                value={values.password}
                onChange={handleChange}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <Locker className="w-5" />
              </span>
            </div>
            {errors.password && touched.password && (
              <p className="ml-5 text-sm font-thin">{errors.password}</p>
            )}
            <div className="w-full relative z-10 mb-1">
              <input
                className="input100"
                type="password"
                name="passwordConfirm"
                placeholder="Confirm your password"
                value={values.passwordConfirm}
                onChange={handleChange}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <Locker className="w-5" />
              </span>
            </div>
            {errors.passwordConfirm && touched.passwordConfirm && (
              <p className="ml-5 text-sm font-thin">{errors.passwordConfirm}</p>
            )}
            <div className="w-full flex justify-center pt-5">
              <button
                className={`login100-form-btn bg-blue-900 font-display tracking-widest font-normal`}
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? <Spinner /> : "Signup"}
              </button>
            </div>
            <>{children}</>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default signupForm;
