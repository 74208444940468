import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Locker, Email } from "../../shop-icons";
import Swal from "sweetalert2";
import { clientLoginAsync, createRequestAsync } from "../../../services/auth";
import Spinner from "../../spinner";

const loginForm = ({ submitHandler, isLoading, children }) => {
  const schema = yup.object({
    email: yup.string().email("Invalid email").required("Email is required"),
    password: yup
      .string()
      .matches(
        /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{6,30})/,
        `The Password should contain at least 6 chars: 
        one uppercase letter, number and a special character (@#$%!...)`
      )
      .required("Password is Required"),
  });

  const handleForgotPassword = () => {
    Swal.fire({
      inputPlaceholder: "Enter your email",
      input: "email",
      inputLabel: "Enter your email and after check your mail box, please.",
      showCancelButton: true,
      inputValidator: async (value) => {
        if (!value) {
          return "Please enter a correct email.";
        }
        let token = await clientLoginAsync();
        await createRequestAsync(value, "ForgotPassword", token);
      },
    });
  };

  return (
    <div className="login100-form max-w-xs">
      <Formik
        validationSchema={schema}
        onSubmit={submitHandler}
        initialValues={{
          email: "",
          password: "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <span className="font-display text-blue-900 text-3xl lg:text-4xl text-center block -mt-10 mb-10">
              Login
            </span>
            <div className="w-full relative z-10 mb-1">
              <input
                className="input100"
                type="email"
                name="email"
                placeholder="Email"
                value={values.email}
                onChange={handleChange}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <Email className="w-5" />
              </span>
            </div>
            {errors.email && touched.email && (
              <p className="ml-5 mb-2 text-sm font-thin">{errors.email}</p>
            )}
            <div className="w-full relative z-10 mb-1">
              <input
                className="input100"
                type="password"
                name="password"
                placeholder="Password"
                value={values.password}
                onChange={handleChange}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <Locker className="w-5" />
              </span>
            </div>
            {errors.password && touched.password && (
              <p className="ml-5 text-sm font-thin">{errors.password}</p>
            )}
            <div className="w-full flex justify-center pt-5">
              <button
                className={`login100-form-btn bg-blue-900 font-display tracking-widest font-normal`}
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? <Spinner /> : "Login"}
              </button>
            </div>
            <>{children}</>
          </form>
        )}
      </Formik>
      <div className="w-full flex justify-center pt-5">
        <button
          className={`text-base font-display cursor-pointer underline text-blue-700`}
          onClick={handleForgotPassword}
          disabled={isLoading}
        >
          Forgot password?
        </button>
      </div>
    </div>
  );
};

export default loginForm;
